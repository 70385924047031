
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/BundleEnums';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chip from '@/components/Chip.vue';
import useBreakpoints from 'vue-next-breakpoints';

import BundleCreateModal from '@/components/bundles/BundleCreateModal.vue';
import BundleUpdateModal from '@/components/bundles/BundleUpdateModal.vue';

import { Bundle } from '@/models/BundleModel';
import toasts from '@/utils/toasts';
import { capitalize, numberFormat } from '@/utils/text';

export default defineComponent({
  name: 'bundle-table',
  components: {
    Card,
    DataTable,
    Chip,
    BundleCreateModal,
    BundleUpdateModal,
  },
  data: () => ({
    bundleTable: '',
    loading: false,
    checkedCustomers: [],
    table: {
      total: 0,
      loading: false,
      items: [],
      search: '',
    },
    showCreateModal: false,
    showUpdateModal: false,
  }),
  computed: {
    ...mapGetters({
      allData: Getters.GET_BUNDLES,
      pagination: Getters.GET_PAGINATION,
      actionErrors: Getters.GET_ACTION_ERROR,
    }),
    isLoading() {
      return this.loading ? this.loading : false;
    },
    dataText() {
      return this.table.total > 1 ? 'bundles' : 'bundle';
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Amount',
          key: 'amount',
          sortable: true,
          align: 'center',
        },
        {
          name: 'Default Entries',
          key: 'quantity',
          sortable: true,
          align: 'center',
        },
        {
          name: 'Description',
          key: 'description',
          sortable: true,
        },
        {
          name: 'Draw Bundles',
          key: 'draw_bundles',
          align: 'center',
        },
        {
          name: 'Active',
          key: 'active',
          sortable: true,
          hidden: this.isMobile,
          align: 'center',
        },
        {
          name: 'Action',
          key: 'action',
          sortable: true,
          align: 'center',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchAllData: Actions.FETCH_BUNDLES,
      createData: Actions.STORE_BUNDLE,
      updateData: Actions.UPDATE_BUNDLE,
      deleteData: Actions.DELETE_BUNDLE,
    }),
    ...mapMutations({
      setData: Mutations.SET_BUNDLE,
    }),
    capitalFirst(str) {
      return capitalize(str);
    },
    formatList() {
      return this.allData.map((item) => {
        return {
          id: item.id,
          amount: item.amount,
          quantity: item.quantity,
          description: item.description,
          draw_bundles: item.draw_bundles,
          mobile_number: item.mobile_number,
          active: item.active == 'yes' ? 'active' : 'inactive',
        };
      });
    },
    async loadData() {
      if (this.loading) return;
      this.loading = true;

      return await this.fetchAllData()
        .then(async () => {
          this.table.items = await this.formatList();
          this.table.total = await this.pagination.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => (this.loading = false));
    },
    getStatus(data: Bundle) {
      if (data?.active === 'active') {
        return {
          type: 'success',
          text: 'Active',
          value: true,
          textStatus: '',
        };
      }

      return { type: 'warning', text: 'Inactive', value: false };
    },

    onSearch(search) {
      this.table.search = search;
    },

    async viewDetail(row: Bundle) {
      return this.$router.push({
        name: 'view-bundle',
        params: { bundle_id: row.id },
      });
    },

    async editDetail(row: Bundle) {
      if (row.draw_bundles) {
        return toasts.alert(`Cannot modify/remove bundle used by draws`);
      }

      this.setData(row);
      this.showUpdateModal = true;
    },

    confirmDelete(row: Bundle) {
      if (row.draw_bundles) {
        return toasts.alert(`Cannot modify/remove bundle used by draws`);
      }

      return toasts.confirm(
        `Are you sure you want to delete this data? You can't undo this action...`,
        (response) => {
          if (response.isConfirmed) {
            this.handleDeleteData(row);
          }
        }
      );
    },

    handleDeleteData(row: Bundle) {
      this.deleteData(row.id)
        .then(() => {
          toasts.success('Bundle Successfully Deleted');

          this.loadData();
        })
        .catch(() => {
          this.loading = false;

          const { errors, message } = this.actionErrors;

          console.log({ errors, message });

          toasts.error(errors, message);
        });
    },

    handleCreated() {
      this.showCreateModal = false;
      this.loadData();
    },

    handleUpdated() {
      this.showUpdateModal = false;
      this.loadData();
    },

    toggleNew() {
      this.showCreateModal = true;
    },

    handleFormCancelled() {
      this.showCreateModal = false;
      this.showUpdateModal = false;
    },

    formatMoney(amount) {
      return numberFormat(amount, 2);
    },
  },

  async mounted() {
    await this.loadData();
  },
});
