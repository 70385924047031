
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import ActiveSelect from '@/components/forms/components/ActiveSelect.vue';

import toasts from '@/utils/toasts';

import {
  Actions as BundleActions,
  Getters as BundleGetters,
} from '@/store/enums/BundleEnums';

import { BundleSchema } from '@/schemas/bundle.schemas';
import { PlanBundle } from '@/models/PlanBundleModel';

const initialValues = {
  amount: '',
  quantity: '',
  active: null,
  description: null,
} as unknown as PlanBundle;

export default defineComponent({
  name: 'bundle-create-form',
  emits: ['dataSubmitted', 'cancelForm'],
  props: {
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    Form,
    FormField,
    ActiveSelect,
  },
  data: () => ({
    typeOptions: [
      {
        label: 'One Time',
        value: 'one_time',
      },
      {
        label: 'Recurring',
        value: 'recurring',
      },
    ] as unknown as [],
    loading: false,
    isLoad: false,
    form: {} as typeof Form,
    formData: initialValues,
  }),
  async mounted() {
    this.form = this.$refs.createBundleForm as typeof Form;
  },
  computed: {
    ...mapGetters({
      actionErrors: BundleGetters.GET_ACTION_ERROR,
    }),

    formSchema() {
      return BundleSchema;
    },
  },
  methods: {
    ...mapActions({
      create: BundleActions.STORE_BUNDLE,
    }),

    handleCancel() {
      this.$emit('cancelForm');
    },

    resetForm() {
      (this.$refs.createBundleForm as typeof Form)?.resetForm();

      // Reset the form
      this.formData = initialValues;
    },
    async submitForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = values;

      this.create(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();

          this.$emit('dataSubmitted');
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;

            // Alert when creation request failed
            toasts.error(errors, message, () => {
              return false;
            });
          }
        });
    },
    handleChangeType(value) {
      this.setFieldValue('type', value);
    },
    handleCloseType() {
      this.validateFormField('type');
    },
    handlePlanSelect(value) {
      this.setFieldValue('account_plan_id', value);
    },
    handlePlanClose() {
      this.validateFormField('account_plan_id');
    },
    handleSelectActive(value) {
      this.setFieldValue('active', value);
    },
    handleCloseActive() {
      this.validateFormField('active');
    },
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    addPrefix(name, prefix = '') {
      return prefix ? `${prefix}_${name}` : name;
    },
  },
});
