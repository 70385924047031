
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import BundleUpdateForm from '@/components/bundles/BundleUpdateForm.vue';
import { Bundle } from '@/models/BundleModel';

export default defineComponent({
  name: 'bundle-update-modal',
  emits: ['update:modelValue', 'formSubmitted', 'close'],
  props: {
    bundle: {
      type: Object,
      default: () => {
        return {} as unknown as Bundle;
      },
    },
    modelValue: { type: Boolean, default: false },
  },
  components: {
    Modal,
    BundleUpdateForm,
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleDataSubmitted() {
      this.$emit('formSubmitted');
    },

    handleClose() {
      this.$emit('close');
    },
  },
});
