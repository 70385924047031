import { createValidationObjectSchema } from '@/utils/validations';

export const BundleSchema = createValidationObjectSchema({
  amount: {
    errorTextFieldLabel: 'Bundle Price',
    required: true,
  },
  quantity: {
    errorTextFieldLabel: 'No of Entries',
    required: true,
  },
  active: {
    errorTextFieldLabel: 'Active',
    required: true,
  },
  description: {
    errorTextFieldLabel: 'Description',
    required: true,
  },
});
